import React from 'react'
import RenderHTMLFromString from './RenderHTMLFromString '

export default function Items({ order }) {

    const items = order.line_items
    const currency_symbol = order.currency_symbol
    const metaFilter = (data) => 
    data.key !== "_ywapo_meta_data" && !data.key.startsWith("_combo") && 
    !data.key.startsWith("_gravity")&& !data.value.startsWith("Tap here to add special instructions")

    return (
        <table className='w-100' >
            <tbody>
                {items.map((item) =>
                    <tr key={item.id} className='align-top'>
                        <td>{item.quantity}x</td>
                        <td className='pb-3 text-break'>
                            <div>{item.name}</div>
                            {
                                item.meta_data
                                    .filter(metaFilter)
                                    .map((data, index) => <RenderHTMLFromString key={index} htmlString={data.display_value} />)
                            }
                        </td>
                        <td className='text-end'>{currency_symbol + item.subtotal}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
