import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const NewOrderModal = ({ orderId, stop }) => {

    const navigate = useNavigate()

    const onView = () => {
        stop()
        navigate("/order/" + orderId)
    }

    return (
        <>
            <button type="button" className="btn btn-primary d-none" id='newOrderModalButton' data-bs-toggle="modal" data-bs-target="#newOrderModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="newOrderModal" tabIndex="-1" aria-labelledby="newOrderModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="newOrderModalLabel">You have a new order</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body fw-bold">
                            ORDER ID : {orderId}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger text-white" data-bs-dismiss="modal" onClick={stop}>Close</button>
                            <button className="btn btn-primary text-white" onClick={onView} data-bs-dismiss="modal">View Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewOrderModal
