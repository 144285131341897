import React from 'react'
import { useNavigate } from 'react-router-dom'
import Tag from './Tag'

export default function MapOrders({ orders }) {

    const navigate = useNavigate()

    const getClass = (status) => {
        const className = "card p-3 shadow-sm"
        switch (status) {
            case "processing": return className + " alert alert-warning"
            case "cancelled": return className + " alert alert-danger"
            case "completed": return className + " alert alert-success"
            case "pending": return className + " alert alert-primary"
            default: return className + " alert alert-light"
        }
    }

    return (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mb-3">
            {orders.map((order) =>
                <div key={order.id} className="col">
                    <div className={getClass(order.status)} onClick={() => navigate('/order/' + order.id)}>

                        <div className='d-flex justify-content-between'>
                            <h3 className='mb-0'>#{order.id}</h3>
                            <Tag status={order.status} />
                        </div>

                        <div className='fs-5'>{order.billing.first_name + ' ' + order.billing.last_name}</div>
                        <p className='mb-0'>{new Date(order.date_created).toString().substring(0, 21)}</p>

                    </div>

                </div>
            )}
        </div>
    )
}
