import React from 'react'

export default function Tag({ status }) {

    const getClass = (status) => {
        const className = "px-3 shadow-sm rounded-pill text-white text-capitalize"
        switch (status) {
            case "processing": return className + " bg-warning"
            case "cancelled": return className + " bg-danger"
            case "completed": return className + " bg-success"
            case "pending": return className + " bg-primary"
            default: return className + " bg-dark"
        }
    }

    const getText = (status) => {
        switch (status) {
            case "pending": return "Payment Pending"
            default: return status
        }
    }

    return (
        <div>
            <span className={getClass(status)}>{getText(status)}</span>
        </div>
    )
}
