import React, { useEffect, useState } from 'react'
import { useUpdateDeliveryTimeMutation } from '../apis/api'
import { BsClockHistory } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

export default function DeliveryTime({ order, refetch }) {

    const convertTo24 = (time) => {
        try {
            if (!time) return ""

            const hour = time.split(":")[0]
            const minute = time.split(":")[1].substring(0, 2)
            const amPm = time.split(":")[1].substring(2)


            if (amPm == "pm") return parseInt(hour) + 12 + ":" + minute
            else return hour + ":" + minute
        }
        catch (err) {
            return ""
        }
    }

    const convertTo12 = (time) => {
        const hour = parseInt(time.split(":")[0])
        const minute = time.split(":")[1]

        if (hour > 12) return hour - 12 + ":" + minute + "pm"
        else return hour + ":" + minute + "am"

    }

    const [update, updateResponse] = useUpdateDeliveryTimeMutation()
    const [time, setTime] = useState(convertTo24(order.meta_data.filter(data => data.key === "delivery_time")[0]?.value))

    const btnClass = "btn btn-primary text-white"

    const onUpdate = () => {

        const metaData = order.meta_data.map((data) => {
            if (data.key === "delivery_time") {
                return { ...data, ...{ value: convertTo12(time) } }
            }
            else return data
        })

        update({ id: order.id, meta_data: metaData })
            .then(() => {
                document.getElementById("closetimeUpdateModal").click()
                refetch()
            })
            .catch(console.log)
    }


    if (order.status === 'processing') {

        return (
            <>

                <button className={btnClass} type='button' data-bs-toggle="modal" data-bs-target="#timeUpdateModal" >
                    <BsClockHistory className='me-2' style={{ height: 25, width: 25 }} />
                    Update
                </button>

                <div className="modal fade" id="timeUpdateModal" tabIndex="-1" aria-labelledby="timeUpdateModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="timeUpdateModalLabel">Update Delivery Time</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <div className='mb-3'>Current Delivery Time: {order.meta_data.filter(data => data.key === "delivery_time")[0]?.value}</div>

                                <div className="form-floating mb-3">
                                    <input type="time" className="form-control" id="timeInput" placeholder="name@example.com" value={time} onChange={(e) => setTime(e.target.value)} />
                                    <label htmlFor="timeInput">Select Delivery Time</label>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='closetimeUpdateModal'>Close</button>

                                {
                                    updateResponse.isLoading ?
                                        <button className="btn btn-primary text-white" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                            Updating...
                                        </button>
                                        :
                                        <button type="button" className="btn btn-primary text-white" onClick={onUpdate}>Update</button>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else return <></>

}
